import { Environment } from "shared-lib";

export const environment: Environment = {
    isDevelopment: false,
    descriptor: "stage",
    portalApiRootUrl: "https://stage.vitupay.com",
    coreApiRootUrl: "https://api.stage.vitupay.com",
    authRootUrl: "https://auth.stage.vitupay.com",
    stackDriverErrorReporting: {
        projectId: "vitupay-oos-stage2",
        key: "AIzaSyBaJzZd-zuX6Z_K8Y9d8k9o0cYBBVuPK30",
        appName: "admin"
    }
};
